/**Kiem soat va hien thi 1 terminal tuong ung voi 1 tai khoan */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Paper,
  TextField,
  Divider,
  Grid,
  LinearProgress,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
  Stack,
  Icon,
  Tooltip,
  Avatar,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
  showSuccess,
} from "../components/common";
import { makeStyles, withStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import numeral from "numeral";
import EditNoteIcon from "@mui/icons-material/EditNote";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import CloseIcon from "@mui/icons-material/Close";
import TerminalStateControl from "./TerminalStateControl";
import OpeningOrderControl from "./OpeningOrderControl";
import PendingOrderControl from "./PendingOrderControl";

const server = require("../lib/server");
const myLib = require("../lib/MyLib");
//Danh sach cac column hien thi open position
const column_style = {
  disableColumnMenu: true,
  disableReorder: true,
  headerAlign: "center",
  align: "center",
};
const orderTypes = ["Buy", "Sell", "Buy-Limit", "Sell-Limit", ""];
/////////HIEN THI VA KIEM SOAT 1 TERMINAL
export class TerminalControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenSmall: window.screen.width < 500,
      terminalData: props.terminalData,
      previousTime: 0,
      terminalTime: props.terminalTime,
    };
    this.columns = [
      {
        field: "symbol",
        headerName: "Symbol",
        disableColumnMenu: true,
        width: 90,

        ...column_style,
        align: "center",
        valueGetter: ({ value, row }) =>
          row.type <= 1 ? value : "[" + value + "]",
      },
      {
        field: "time",
        headerName: "Time",
        width: 135,
        ...column_style,
      },

      {
        field: "type",
        headerName: "Type",
        disableColumnMenu: true,
        width: 70,
        ...column_style,
        valueGetter: ({ value, row }) => orderTypes[value],
        renderCell: ({ value, row }) =>
          row.type == 0 || row.type == 2 ? (
            <span style={{ color: "blue" }}>{value}</span>
          ) : (
            <span style={{ color: "red" }}>{value}</span>
          ),
      },
      {
        field: "volume",
        headerName: "Volume",
        disableColumnMenu: true,
        width: 75,
        ...column_style,
        headerAlign: "right",
        align: "right",
        valueGetter: ({ value, row }) =>
          row.id > 0 ? (row.volume ? row.volume.toFixed(2) : null) : value,
        colSpan: ({ value, row }) => (row.id == 0 ? 2 : 1),
        renderCell: ({ value, row }) =>
          row.id > 0 ? (
            value
          ) : (
            <span style={{ color: "black", fontWeight: "bolder" }}>
              {value}
            </span>
          ),
      },
      {
        field: "price",
        headerName: "Price",
        width: 85,
        ...column_style,
        headerAlign: "right",
        align: "right",

        valueGetter: ({ value, row }) =>
          value ? numeral(row.price).format("0,0.00") : null,
      },
      {
        field: "stopLoss",
        headerName: "S/L",
        width: 75,
        ...column_style,
        headerAlign: "right",
        align: "right",

        valueGetter: ({ value, row }) =>
          row.id
            ? row.stopLoss
              ? numeral(value).format("0,0.00")
              : ""
            : value,
        colSpan: ({ value, row }) => (row.id == 0 ? 2 : 1),
        renderCell: ({ value, row }) =>
          row.id > 0 ? (
            value
          ) : (
            <span style={{ color: "black", fontWeight: "bolder" }}>
              {value}
            </span>
          ),
      },
      {
        field: "takeProfit",
        headerName: "T/P",
        width: 75,
        ...column_style,
        headerAlign: "right",
        align: "right",

        valueGetter: ({ value, row }) =>
          row.id
            ? row.takeProfit
              ? numeral(value).format("0,0.00")
              : ""
            : value,
      },
      {
        field: "profit",
        headerName: "Profit",
        width: 80,
        ...column_style,
        headerAlign: "right",
        align: "right",
        renderCell: ({ value, row }) =>
          row.type <= 1 && value >= 0 ? (
            <span style={{ color: "blue" }}>
              {numeral(value).format("0,0.00")}
            </span>
          ) : row.type <= 1 ? (
            <span style={{ color: "red" }}>
              {numeral(value).format("0,0.00")}
            </span>
          ) : (
            <span style={{ color: "black", fontWeight: "bolder" }}>
              {value}
            </span>
          ),
      },
      {
        field: "comment",
        headerName: "Comment",
        width: 150,
        ...column_style,
      },
      {
        field: "action",
        headerName: "Action",
        width: 130,
        ...column_style,
        renderCell: ({ value, row }) =>
          row.id > 0 ? (
            <Stack direction={"row"}>
              <IconButton
                onClick={() => {
                  this.props.onAction("modify", this.state.terminalData, row);
                }}
              >
                <EditNoteIcon color="primary" />
              </IconButton>
              {row.type <= 1 ? (
                <IconButton
                  onClick={() => {
                    this.props.onAction(
                      "partial",
                      this.state.terminalData,
                      row
                    );
                  }}
                >
                  <PlaylistRemoveIcon color="warning" />
                </IconButton>
              ) : null}
              <IconButton
                onClick={() => {
                  this.props.onAction(
                    row.type <= 1 ? "close" : "cancel",
                    this.state.terminalData,
                    row
                  );
                }}
              >
                <CloseIcon color="error" />
              </IconButton>
            </Stack>
          ) : null,
      },
    ];
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      terminalData: nextProps.terminalData,
      previousTime: prevState.terminalTime, //ghi lai thoi gian truoc
      terminalTime: nextProps.terminalTime,
    };
  }
  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.terminalTime != this.state.previousTime;
  }
  render() {
    let terminalData = this.state.terminalData;
    /*let openPositons = this.state.terminalData.Positions; //danh sach lenh dang open
    let pendingOrders = this.state.terminalData.Orders; //danh sach lenh cho
    var total = 0;
    openPositons.map((p) => (total += p.profit));
    var id = 0;
    var sum = {
      symbol: "SUM",
      type: 4,
      volume: "B: " + numeral(terminalData.Balance).format("0,0.00"),
      stopLoss: "E: " + numeral(terminalData.Equity).format("0,0.00"),
      profit:
        total >= 0
          ? numeral(total).format("0,0.00")
          : "(" + numeral(total).format("0,0.00") + ")",
    };
    var list = [].concat(openPositons);
    list.sort((a, b) => {
      let s1 = a.symbol.localeCompare(b.symbol);
      let s2 = a.comment.localeCompare(b.comment);
      return s1 || s2;
    });
    list.splice(0, 0, sum);
    list = list.concat(pendingOrders);
    list.map((item) => (item.id = id++));*/
    var list = terminalData.AllOrders ?? [];
    return (
      <Grid container columns={24} spacing={1}>
        <Grid item xs={24}>
          <hr />
        </Grid>
        <Grid item xs={24}>
          <TerminalStateControl
            terminalData={terminalData}
            onAction={(button) =>
              this.props.onAction(button, this.props.terminalData)
            }
          />
        </Grid>
        <Grid item xs={24}>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <DataGrid
              rows={list}
              columns={this.columns}
              columnHeaderHeight={28}
              rowHeight={32}
              showCellVerticalBorder
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
              hideFooter={true}
              hideFooterPagination={true}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles({}, { withTheme: true })(TerminalControl);
